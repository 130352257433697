import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WalletSignIn from './components/WalletSignIn';
import Home from './components/Home';
import AdminPost from './components/AdminPost';
import WalletComponent from './components/WalletComponent';

function App() {
  const [appWalletData, setAppWalletData] = useState(null);

  const handleWalletDataChange = async (walletData) => {
    setAppWalletData(walletData);
  };
  
  return (
  <div className='bg-black text-light vh-100 pt-mono-regular d-flex flex-column justify-content-center'>
    <Router>
      <div className='d-flex justify-content-between py-2 border-bottom border-secondary align-items-center px-3'>
          <a href='https://garfsolana.com/' target='_blank' rel='noopener noreferrer' className='fw-bold mb-0 border-bottom h3' style={{ color: 'inherit', textDecoration: 'none' }}>$GARF</a>
        <span/>
        <WalletComponent walletData={handleWalletDataChange} />
      </div>
      <Routes>
        <Route path='/' element={<Home walletDataHome={appWalletData}/>} />
        <Route path='/admin' element={<AdminPost unparsedWalletData={appWalletData} />} />
      </Routes>
    </Router>
  </div>
  );
}

export default App;
