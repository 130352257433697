import React, { useState, useEffect } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

function Post({ walletProp, postTitle, postId, postContent, totVoteCount, posVotePower, negVotePower }) {
    const [localPosVotePower, setLocalPosVotePower] = useState(posVotePower);
    const [localNegVotePower, setLocalNegVotePower] = useState(negVotePower);
    const [finalVoteScale, setFinalVoteScale] = useState(50); // Default value for vote scale
    const [voteVerdict, setVoteVerdict] = useState("0.00%"); // Default default verdict

    // Define the function before using it in useEffect
    const votePercentage = async () => {
        let localFinalVoteScale;
        let localVoteVerdict;

        if (localPosVotePower > localNegVotePower) {
            const voteScalePreCalc = localPosVotePower - localNegVotePower;
            const truncatedValue = voteScalePreCalc.toFixed(3);
            localVoteVerdict = truncatedValue + "%";
            localFinalVoteScale = 50 + voteScalePreCalc / 2;
        } else if (localNegVotePower > localPosVotePower) {
            const voteScalePreCalc = localNegVotePower - localPosVotePower;
            const truncatedValue = voteScalePreCalc.toFixed(3);
            localVoteVerdict = truncatedValue + "%";
            localFinalVoteScale = 50 - voteScalePreCalc / 2;
        }

        setVoteVerdict(localVoteVerdict);
        setFinalVoteScale(localFinalVoteScale);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLocalPosVotePower(posVotePower);
                setLocalNegVotePower(negVotePower);
                votePercentage();
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

        fetchData();
    }, [posVotePower, negVotePower]);

    const handleVote = async (voteVerdict) => {
        try {
            const messageText = voteVerdict === 1 
                ? "You're about to vote in favor of this poll."
                : "You're about to vote not in favor of this poll.";
            const message = new TextEncoder().encode(messageText);
            await walletProp.signMessage(message);

            const walletId = walletProp.publicKey.toBase58();

            const response = await fetch(apiUrl + 'vote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    walletId,
                    postId,
                    voteVerdict 
                })
            });

            if (!response.ok) {
                throw new Error('Failed to record vote, please try again.');
            }

            const action = voteVerdict === 1 ? "in favor" : "not in favor";
            alert(`Your vote for Poll #${postId} ${action} has been registered!`);
        } catch (err) {
            console.error('Error:', err);
            alert('Your vote was not registered.');
        }
    };

    return (
        <div className="p-3 bg-black mb-5 rounded text-center pb-0" style={{border: '2px solid #ff7f00' }}>
            <div className='d-flex justify-content-between align-items-center'>
                <span className='col'/>
                <div className='col text-center'>
                    <h2 className='fw-bold'>{postTitle}</h2>
                </div>
                <div className='col d-flex justify-content-end'>
                    <h6 className='text-secondary'>Poll id: <span>#{postId}</span></h6>
                </div>
            </div>
            <p>{postContent}</p>
            <div className="d-flex justify-content-between mt-3">
                <button className="btn" style={{background:'#ff7f00', borderColor:'white', borderWidth: '1px'}} onClick={() => handleVote(1)}>In favour</button>
                <button className="btn btn-light" style={{borderColor:'#ff7f00', borderWidth: '2px'}} onClick={() => handleVote(0)}>Not in favour</button>
            </div>
            <div className='mt-3'>
                <div className='progress' role="progressbar" aria-valuenow={finalVoteScale} aria-valuemin="0" aria-valuemax="100" style={{height: '20px'}}>
                    <div className='progress-bar bg-light d-flex justify-content-end align-items-end' style={{width: `${finalVoteScale}%`, background: 'radial-gradient(circle, #c26202, #ff7f00)'}}>
                        <p className='my-auto text-center align-items-end badge badge-pill bg-dark'>{voteVerdict}</p>
                    </div>
                </div>
            </div>
            <div>
                <p className='text-secondary mt-3 text-center'># of votes: {totVoteCount}</p>
            </div>
        </div>
    );
}

export default Post;