import React, { useEffect, useState } from 'react';
import Post from './Post';

const apiUrl = process.env.REACT_APP_API_URL;

function Home({ walletDataHome }) {
    const [posts, setPosts] = useState([]); // State to hold posts

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl + "posts");
                if (response.ok) {
                    const data = await response.json();
                    setPosts(data);
                } else {
                    throw new Error('Failed to fetch posts');
                }
            } catch (err) {
                console.error("Error fetching posts:", err);
            }
        };

        fetchData();
    }, []);

    return (
            <div className='d-flex justify-content-center p-3' style={{ background: 'radial-gradient(circle, #ff7f00, #000000)', height: "100%"}}>
                <div className='container text-center'>
                    <div className='d-flex justify-content-center' style={{ width: '100%' }}>
                        <div className='text-center' style={{ width: '10%'}}>
                            <h1 className='text-white border-bottom fw-bold'>Feed</h1>
                        </div>
                    </div>
                    <div className='container text-center p-3 border rounded mt-2' style={{backgroundColor: 'rgba(0, 0, 0, .75)', height: '80vh', overflowY: 'auto' }}>
                        {posts.map(post => (
                            <Post
                                key={post.post_id}
                                walletProp={walletDataHome}
                                postTitle={post.post_title}
                                postId={post.post_id}
                                postContent={post.post_content}
                                totVoteCount={post.tot_vote_count}
                                posVotePower={post.pos_vote_power}
                                negVotePower={post.neg_vote_power}
                            />
                        ))}
                    </div>
                </div>
            </div>
    );
    
}

export default Home;
