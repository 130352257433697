import React, { useState } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

function AdminPost({ unparsedWalletData }) {
    const [postTitle, setPostTitle] = useState('');
    const [postContent, setPostContent] = useState('');
    const [error, setError] = useState(null);

    const handlePostSubmit = async (event) => {
        event.preventDefault(); // Prevent page reload
        
        try {
            const walletId = unparsedWalletData.publicKey.toBase58();
            const signMessageData = new TextEncoder().encode('Are you sure you want to make this post? Please double check everything before posting.');
            await unparsedWalletData.signMessage(signMessageData);

            const response = await fetch(apiUrl+'adminPostCreation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    walletId,
                    postTitle,
                    postContent,
                }),
            });

            if (response.ok) {
                setPostTitle('');
                setPostContent('');
            } else {
                throw new Error('Failed to create post.');
            }
        } catch (error) {
            console.error('Error creating post:', error);
            setError(error.message);
        }
    };

    return (
        <div className='p-5 d-flex justify-content-center text-center align-items-center' style={{height: '90%'}}>
            <form className='w-75' onSubmit={handlePostSubmit}>
                <div className='mb-5'>
                    <div className="form-group mb-2">
                        <label htmlFor="exampleFormControlInput1">Title of post</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Title of post"
                            value={postTitle}
                            onChange={(event) => setPostTitle(event.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="postContent">Post content</label>
                        <textarea
                            className="form-control"
                            rows="6"
                            placeholder="Post content"
                            value={postContent}
                            onChange={(event) => setPostContent(event.target.value)}
                        ></textarea>
                    </div>
                    {error && <div className="alert alert-danger" role="alert">{error}</div>}
                </div>
                <button type='submit' className='btn btn-success'>Post</button>
            </form>
        </div>
    );
}

export default AdminPost;
