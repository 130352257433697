import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { ConnectionProvider, WalletProvider, useWallet } from "@solana/wallet-adapter-react";
import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import "@solana/wallet-adapter-react-ui/styles.css";

const WalletComponent = ({walletData}) => {
  const [childData, setChildData] = useState(null);
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(() => [], []);

  const handleDataFromChild = (initialWalletData) => {
    setChildData(initialWalletData);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (childData !== null) {
        walletData(childData);
      }
    };

    fetchData();
  }, [childData]);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <Content initialWalletData={handleDataFromChild} />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

const Content = ({ initialWalletData }) => {
  const wallet = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    const signMessageAndNavigate = async () => {
      if (wallet.connected && wallet.publicKey) {
        initialWalletData(wallet);
      }
    };
    signMessageAndNavigate();
  }, [wallet.connected, wallet.publicKey, navigate]);

  return (
    <div>
      <WalletMultiButton />
    </div>
  );
};

export default WalletComponent;